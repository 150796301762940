@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html,
body,
#root {
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    padding: 0;
    margin: 0;

    font-family: 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

.container {
    margin: auto;
}



.react-select__control {
    width: 100% !important;
    outline: none !important;
    border-width: 0 !important;
    min-height: 0 !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.react-select__value-container {
    padding-left: 0 !important;
}

.react-select__indicator {
    padding-right: 0 !important;
}

.react-select__menu {
    margin-left: -8px !important;
}